<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('SelectChildren')"
    v-on:submit="save"
    content-class="pl-0 pr-0 mb-2 max-400"
    :hide-submit="error || !children || children.length == 0"
  >
    <template v-slot:content>
      <v-list v-if="children && children.length > 0" class="pt-0 pb-0">
        <v-list-item-group
          v-model="selected"
          active-class="global-list-item-active"
          multiple
        >
          <template v-for="(child, index) in children">
            <v-list-item :key="child.id">
              <template v-slot:default="{ active }">
                <v-list-item-avatar>
                  <v-img
                    :src="$imagehelpers.childAvatar(child.avatar, 300)"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="global-list-header mb-0">{{
                    child.name | capitalize
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon v-if="!active" color="grey lighten-1">
                    $vuetify.icons.starOutline
                  </v-icon>

                  <v-icon v-else color="yellow darken-3">
                    $vuetify.icons.star
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < children.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div v-else class="text-center pb-5">{{ $t("NoChildren") }}</div>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "SelectChildren": "Select children",
      "MethodNotFound": "Method not found! Refresh the page and try again.",
      "PostUpdated": "Post updated!",
      "NoChildren": "There is no children to select!",
      "InfoUpdated": "Information updated!"
    },
    "sv": {
      "SelectChildren": "Välj barn",
      "MethodNotFound": "Anropet kunde inte genomföras. Vänligen uppdatera sidan och försök igen.",
      "PostUpdated": "Inlägget uppdaterades!",
      "NoChildren": "Det finns inga barn att välja!",
      "InfoUpdated": "Informationen uppdaterades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "../../../_services";

export default {
  name: "diary_select_children_dialog",
  computed: {
    ...mapState({
      global_children: (state) => state.diary.children.list,
    }),
  },
  components: {},
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,

    post: null,
    media: null,

    children: [],
    selected: [],
  }),
  created: function () {},
  methods: {
    ...mapActions({
      dbUpdateBlogPostChildren: "diary/news/updateChildren",
    }),
    rowClick(child) {
      child.is_selected = !child.is_selected;
    },
    start() {
      var self = this;
      self.selected = [];
      self.children = [...self.global_children];
      self.is_saving = false;
      self.error = null;
      self.is_open = true;
    },
    setMarked(selectedChildren) {
      var self = this;
      self.children.forEach((child, i) => {
        if (selectedChildren) {
          var ch = selectedChildren.find((p) => p.id == child.id);

          if (ch) {
            self.selected.push(i);
          }
        }
      });
    },
    getMarked() {
      var data = [];
      var self = this;

      self.selected.forEach((val) => {
        data.push(self.children[val]);
      });

      return data;
    },

    openBlogPost(post) {
      var self = this;
      self.start();
      self.post = { ...post };

      self.setMarked(self.post.children);
    },
    openMedia(media) {
      var self = this;
      self.start();
      self.media = { ...media };

      self.setMarked(self.media.children);
    },
    save() {
      var self = this;
      self.error = null;
      self.is_saving = true;

      if (self.post) {
        self.updateBlogPostChildren();
      } else if (self.media) {
        self.updateMediaChildren();
      } else {
        self.$errorNort(self.$t("MethodNotFound"));
      }
    },

    updateMediaChildren() {
      var self = this;
      var data = self.getMarked();

      var postData = [];

      for (let i = 0; i < data.length; i++) {
        postData.push({ id: data[i].id });
      }

      albumsService
        .saveChildrenInMedia(self.media.id, postData)
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.media.children = data;
          self.$emit("updated", self.media);
          self.$successNoty(self.$t("InfoUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
    updateBlogPostChildren() {
      var self = this;
      var data = self.getMarked();
      self
        .dbUpdateBlogPostChildren({
          id: self.post.id,
          children: data,
        })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.post.children = data;
          self.$emit("updated", self.post);
          self.$successNoty(self.$t("PostUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
  },
};
</script>